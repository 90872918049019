// $background: #eeffae;
// $primary:    #181818;
// $accent:    #08e;

$background: #2e2e2e;
$background2: #1e1e1e;
$primary:    #bbb;
$accent:    #08e;
$accent2:    #0e8;

$container: 1400px;
$navwidth: 300px;
$navbreakpoint: 900px;

$m1: calc(min(10px + 2vw, 30px));
$m2: calc(min(15px + 1.5vw, 40px));
$m3: calc(min(20px + 2vw, 50px));
$m4: calc(min(45px + 3.5vw, 100px));

@font-face {
  font-family: manrope;
  src: url("/assets/fonts/manrope.woff2") format("woff2 supports variations"),
       url("/assets/fonts/manrope.woff2") format("woff2-variations");
  font-weight: 100 1000;
}

* { margin: 0; padding: 0; }

html {
    background: $background;
    color: $primary;
    font-size: calc(15px + 0.390625vw);
    height: 100%;
}

a {
    color: $accent;
    text-decoration: none;
}

body {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $container;
    margin: 0 auto;
    height: 100%;
    font-family: manrope;
    font-weight: 450;
    & > * { box-sizing: border-box; }
}

.wrapper { flex: 1 0 auto; }

nav {
    padding: $m1;

    h1 a { color: inherit; }

    ul {
        display: inline-flex;
        gap: 1rem;

        li[aria-current]:not([aria-current="false"]) *{
            color: $accent2;
        }
    }
}

footer {
    margin-top: 10rem;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: $m2;
}

header {
    padding: $m1;
    background-size: cover;
    background-position: center;
    z-index: 20;

    text-shadow: -1px -1px 0 $background, 1px -1px 0 $background, -1px 1px 0 $background, 1px 1px 0 $background;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.38);


    & > * { max-width: 85vw; }

    &.big { padding: $m4 $m1; }
    &.dark { 
        background-color: $background2; 
        text-shadow: -1px -1px 0 $background2, 1px -1px 0 $background2, -1px 1px 0 $background2, 1px 1px 0 $background2;
    }


    display: flex;
    flex-direction: column;
    gap: .5rem;

    h1 { 
      font-size: 2.6rem; 
      line-height: 2.6rem; 
      font-weight: 700;
    }

    h2 { 
        font-size: 2rem; 
        line-height: 2.5rem; 
        font-weight: 500;
    }
}


main {
    padding: $m1 0;
    > * { padding: 0 $m1;}
    h1 { font-size: 1.6rem; margin: 1.6rem 0; }
    h2 { font-size: 1.3rem; margin: .8rem 0; }
    h3 { font-size: 1.1rem; margin-bottom: .8rem; font-weight: 550;}

    details {
        summary {
            list-style: inside disclosure-closed;
        }
        &[open] summary {
            list-style: inside disclosure-open;
        }
    }
    p, ul, ol { margin-bottom: 1rem; }
    > p, > ul, > ol, section > p, section > ul, section > ol {
        @media (min-width: 900px) {
            max-width: 64%;
        }
    }
    ul > li {
        list-style: "-  ";
        list-style-position: inside;
        margin-bottom: .2rem;
    }
    li > p {
        display: inline;
    }
    figure {
        // display: flex;
        // flex-direction: column;
        // display: grid;
        width: 100%;
        column-gap: 1rem;
        // grid-template-areas: "caption" "figure"; 

        margin-bottom: 2rem;

        // @media (min-width: 900px) {
        //     grid-template-areas: "figure caption" ;
        //     grid-template-columns: 9fr 5fr;
        // }

        img {
            // grid-area: figure;
            max-height: 90vh;
            max-width: 100%;
            // width: 100%;
            margin-left: 50%;
            transform: translateX(-50%);
            align-self: end;
            background: $background2;
        }

        // @media (max-width: 899px) {
        //     .map-container {
        //         grid-area: figure;
        //     }
        // }

        figcaption {
            // grid-area: caption;
            // margin: auto;
        }
    }


    section { margin-bottom: $m1; }

    section.map { 
        padding: 0; 
        .map-container {
            background: $background2;
            .map {
                height: 100%;
                width: 100%;
            }
            @media (min-width: 900px) {
                position: fixed;
                bottom: 0;
                right: 0;
                min-width: 400px;
                max-width: 650px;
                width: 35.7vw;
                aspect-ratio: 5 / 3;
                z-index: 10;
            }
            @media (max-width: 899px) {
                height: 75vh;
                margin-bottom: 1rem;
            }
        }
        p, ul, ol { padding: 0 $m1;}
        ul {
            gap: 1rem;
            li { 
            display: inline-block;
            padding-right: .6rem;
            list-style: none;
        }
        }
    }

    .cardGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        @media (min-width: 900px) {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }
        gap: $m1;

        .card {
            padding: 1rem;
            color: transparent;


            aspect-ratio: 5 / 3;
            background-size: cover;
            background-position: center;
            color: inherit;
            box-shadow: inset 0 0 0 1000px rgba(0,0,0,.38);
            text-shadow: -1px -1px 0 $background, 1px -1px 0 $background, -1px 1px 0 $background, 1px 1px 0 $background;
            &:hover { 
                color: inherit;
                box-shadow: inset 0 0 0 1000px rgba(0,0,0,.38);
                text-shadow: -1px -1px 0 $background, 1px -1px 0 $background, -1px 1px 0 $background, 1px 1px 0 $background;
            }
            h3 { 
                font-size: 1.4rem; 
                line-height: 1.4rem; 
                font-weight: 600;
            }
            h4 { 
                font-size: 1.2rem; 
                line-height: 1.3rem; 
                font-weight: normal;
            }
        }
    }
}

#sketch {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    z-index: -100;
}

#sketch-select {
    border: 1px solid $primary;
    padding: .2rem .6rem;
    cursor: pointer;
    
    option {
        color: $background;
    }
    option:hover {
      background: $primary;
      color: $background;
      box-shadow: 0 0 10px 100px #1882A8 inset;
    }
}

#player {
    .metadata {
        display: flex;
        flex-direction: column;
        font-size: 1.8rem;
    }
    .control {
        width: auto;
        cursor: pointer;
        font-size: 1.2rem;
        &.play::before { content: "play"; }
        &.loading::before { content: "loading..."; }
        &.stop::before { content: "stop"; }
    }
}


